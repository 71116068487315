.breadcrumbs {
  @apply flex items-center;
}

.breadcrumbs.defaultTextColor{
  @apply text-gray-900;
}

.breadcrumbs a {
  color: inherit;
}

.breadcrumbs.defaultTextColor a {
  @apply text-gray-800;
}

.breadcrumbs li:first-of-type:before {
  @apply hidden;
}

.breadcrumbs li:before {
  content: '/';
  color: inherit;
  @apply px-1
}

.breadcrumbs.defaultTextColor li:before {
  @apply text-gray-500;
}

@media (max-width: 768px) {
  .breadcrumbs {
    @apply flex-nowrap justify-start px-0 mx-0 overflow-auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
  .breadcrumbs li:last-of-type {
    @apply pr-4;
  }
}
