.parkList {
  @apply gap-6 mb-4;
  column-count: 4;
}

.region {
  @apply mb-4;
}

.regionTitle {
  @apply flex flex-col;
  break-inside: avoid;
}

.region .regionTitle h3 {
  @apply pb-3 mb-0 text-xl font-medium;
}

.link {
  @apply flex pb-2;
}

@media (max-width: 768px) {
  .parkList {
    column-count: 3;
  }
}
@media (max-width: 576px) {
  .parkList {
    column-count: 1;
  }
}
