.parkDirectoryContainer {
  @apply flex flex-col items-center bg-canvas-100;
}

.parkDirectoryContainer h1 {
  @apply text-5xl font-bold leading-none;
}

.parkDirectoryContainer h2 {
  @apply text-3xl font-bold;
}

.parkDirectoryContainer h3 {
  @apply mb-4 text-xl font-bold;
}

.parkDirectoryContainer h4 {
  @apply mb-2 text-base font-bold;
}

.viewAll {
  @apply text-lg font-bold;
}

.breadcrumbs {
  @apply my-4;
}
